import { render, staticRenderFns } from "./WalletButton.vue?vue&type=template&id=c169d8bc&scoped=true&"
import script from "./WalletButton.vue?vue&type=script&lang=js&"
export * from "./WalletButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c169d8bc",
  null
  
)

export default component.exports