<template>
  <div class="wallet-topup-button">
    <div class="wtb__desc">
      <div class="wtb__type">
        <h6>{{entryVal}}</h6>
      </div>
      <div
        class="wtb__subdesc"
        v-if="valTrueVal !== null"
      >
        <p>
          {{valTrueVal}}
        </p>
      </div>
      <div
        class="wtb__cockade"
        v-if="hasDiscount"
      >
        <font-awesome-icon icon="certificate"/>
        <div class="wtb__cockade__value">
          {{discountValue}}
        </div>
      </div>
    </div>
    <div
      v-if="isOperative"
      class="wtb__actions"
    >
      <b-button
        variant="outline-primary"
        @click="doTopUp()"
        size="sm"
      >
        <font-awesome-icon icon="credit-card"/>
      </b-button>
    </div>
  </div>
</template>

<script>

import { formatDecimalNumber } from '../../utils/formatterHelper';
import { isNotEmpty } from '../../utils/validators';

export default {
  name: 'WalletButton',
  components: {},
  props: {
    item: Object,
    buyProduct: Function,
    priceDenominator: Number,
  },
  computed: {
    entryVal() {
      return `Ricarica da ${this.item.creditsPurchased.amount} ${this.item.creditsPurchased.currency}`;
    },
    valTrueVal() {
      // val difference - discount
      return `Al prezzo di ${this.item.price.amount} ${this.item.price.currency}`;
    },
    hasDiscount() {
      return this.discount > 0;
    },
    discount() {
      const myRatio = this.item.creditsPurchased.amount / this.item.price.amount;
      const percent = (myRatio - this.priceDenominator) / this.priceDenominator;
      return percent;
    },
    discountValue() {
      return `${formatDecimalNumber(this.discount * 100, 0, 'it')}% off`;
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
  },
  methods: {
    doTopUp() {
      // console.log('show top-up modal/confirm', this.item.creditsPurchased.amount);
      if (this.buyProduct) {
        this.buyProduct(this.item);
      }
    },
  },
};
</script>

<style scoped>

</style>
